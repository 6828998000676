@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik";
  -webkit-tap-highlight-color: transparent;
}

:root {
  /* Breakpoints */
  --mobileBreakpoint: 1024px;
  --pageWidth: 1300px;
  --headerHeight: 70px;
  /* Farbpalette */
  --primaryColor: rgba(210, 162, 76, 1);
  --highlightColor: rgba(115, 189, 168, 1);
  --errorColor: rgba(204, 107, 73, 1);
  --backgroundColor: rgba(236, 230, 194, 1);
  --backgroundColorTransparent: rgba(236, 230, 194, 0.5);
  --borderColor: rgba(111, 86, 67, 1);
  --primaryColorFaded: rgba(36, 105, 69, 0.5);
}

a {
  display: flex;
}
a:link {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration-line: none;
  color: black;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#NoBulletPoints li {
  list-style: none;
}

h1 {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0px;
}

span {
  padding: 0px;
  margin: 0px;
}

.ButtonPressEffect {
  transition: 0.02s;
}

.ButtonPressEffect:active {
  box-shadow: none;
  margin-top: 2px;
  margin-bottom: -2px;
  margin-left: 1px;
  margin-right: -1px;
}
