.HowToPage__container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
}

.HowToPage__content {
  width: 100%;
  max-width: var(--pageWidth);
}
