.Stopwatch__container {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: white;
  color: var(--backgroundColor);
  font-size: 13px;
  -webkit-text-stroke: 1px var(--borderColor);
  background-color: var(--primaryColor);
  height: 100%;
  border-radius: 100vmax;
  min-width: 100px;
  padding-right: 1px;
  gap: 5px;
  border: solid var(--borderColor) 2px;
  box-shadow: 1px 2px 0px var(--borderColor);
  padding-right: 0px;
  padding-left: 7px;
}

.Stopwatch__pausebutton {
  /* border: solid red 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--highlightColor);
  height: calc(100% + 4px);
  border-radius: 100vmax;
  border: solid var(--borderColor) 2px;
  cursor: pointer;
  margin-right: -2px;
}
