.SettingBar__container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.SettingBar__container button {
  width: 100%;
  border: solid black 1px;
  cursor: pointer;
  height: 40px;
  font-size: 1.1rem;
}

#SettingBar_Setting {
  /* border-radius: 5px 0px 0px 5px; */
  border-right: none;
}
#SettingBar_Solving {
  /* border-radius: 0px 5px 5px 0px; */
}

@media (max-width: 1040px) {
  .SettingBar__container button {
    height: 30px;
  }
}
