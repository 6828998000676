.CellComp__container {
  border: solid var(--borderColor) 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  -webkit-tap-highlight-color: transparent;
}

.Index_Cell {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "upleft upright"
    "center center"
    "lowleft lowright";
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  padding: 2%;
  /* background-color: aquamarine; */
}
.UpperCornerLeft {
  display: flex;
  grid-area: upleft;
  /* background-color: blue; */
}
.UpperCornerRight {
  display: flex;
  justify-content: end;
  grid-area: upright;
  /* background-color: blue; */
}
.Center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-area: center;
  text-align: center;
  /* font-weight: 300; */
  /* background-color: green; */
}
.LowerCornerLeft {
  display: flex;
  align-items: end;
  grid-area: lowleft;
  /* background-color: blue; */
}
.LowerCornerRight {
  display: flex;
  align-items: end;
  justify-content: end;
  grid-area: lowright;
  /* background-color: blue; */
}
