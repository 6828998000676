.MainGrid__container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  /* min-width: 600px; */
  /* max-width: 600px; */
  aspect-ratio: 1 / 1;
  /* border: solid blue 1px; */
}

.MainGrid__pause-overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor);
  opacity: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--highlightColor);
  -webkit-text-stroke: 2px var(--borderColor);
  font-size: 3rem;
  font-weight: bold;
  z-index: 1;
}

.MainGrid__finished-overlay {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
