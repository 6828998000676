.SolverPage__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 3rem 1rem 0 1rem;
  /* border: solid red 1px; */
}

.SolverPage__sudokuapp {
  max-width: var(--pageWidth);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
