.FinishOverlay__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* width: 300px;
  height: 250px; */
  background-color: white;
  border-radius: 20px;
  border: solid var(--borderColor) 2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  gap: 1rem;
  cursor: auto;
}

.FinishOverlay__heading {
  color: var(--highlightColor);
  font-size: 32px;
  letter-spacing: -0.1rem;
  -webkit-text-stroke: 2px var(--borderColor);
}

.FinishOverlay__stats {
  font-family: "Rubik";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 230px;
  height: 100px;
  /* border: solid red 1px; */
}

.FinishOverlay__stat-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: solid red 1px; */
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  color: var(--borderColor);
  /* height: 40px; */
}

.FinishOverlay__stat-row span::first-letter {
  text-transform: uppercase;
}

.FinishOverlay__stat-row-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.FinishOverlay__stat-icon svg {
  stroke: var(--borderColor);
  fill: var(--borderColor);
  /* border: solid blue 1px; */
  width: 24px;
  height: 24px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.FinishOverlay__buttons button {
  background-color: var(--highlightColor);
  color: var(--backgroundColor);
  -webkit-text-stroke: 2px var(--borderColor);
  border: solid var(--borderColor) 2px;
  border-radius: 10px;
  font-size: 25px;
  width: 100px;
  height: 50px;
  box-shadow: 1px 2px 0px var(--borderColor);
  /* transition: 0.02s; */
  cursor: pointer;
}
/* 
.FinishOverlay__buttons button:active {
  box-shadow: none;
  margin-top: 2px;
  margin-bottom: -2px;
  margin-left: 1px;
  margin-right: -1px;
} */
