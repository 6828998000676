.ActionBarButton__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.ActionBarButton__container img {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
}

.ActionBarButton_Subtext {
  font-size: 13px;
}
