.CustomPage__container {
  /* border: solid purple 1px; */
  width: 100%;
  max-width: var(--pageWidth);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
}

.CustomPage_SudokuApp {
  max-width: 1050px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (max-width: 1040px) {
  .CustomPage_SudokuApp {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
}
