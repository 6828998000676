.Solver__container {
  width: 100%;
}
.Solver__container button {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-style: none;
  border: solid black 1px;
  font-size: 1.1rem;
  background-color: black;
  color: white;
  border-radius: 10px;
}
