.Header_Desktop__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 30px;
  width: 100%;
  box-sizing: border-box;

  padding: 0rem 1rem 0rem 1rem;
  list-style: none;
  border-bottom: solid var(--borderColor) 2px;
  user-select: none;
}

.Header_Desktop__content {
  display: flex;
  /* border: solid black 1px; */
  max-width: var(--pageWidth);
  width: 100%;
  height: 100%;
  align-items: center;
}

.Header_Desktop__container ul {
  display: flex;
  gap: 2rem;
  list-style: none;
  padding-left: 3rem;
}

.Header_Desktop__container li {
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
}

/* MOBILE HEADER */

.Header_Mobile__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--backgroundColor);
  border-bottom: solid var(--borderColor) 2px;
  user-select: none;
}

.Header_Mobile__Bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--headerHeight);
  width: 100%;
  /* border-bottom: solid black 1px; */
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;
  font-size: 30px;
  height: 50px;
}

/* .Header_Mobile__Bar img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
} */

.Header_Mobile__Dropdown {
  /* border-bottom: solid black 1px; */
  padding-right: 1rem;
}
.Header_Mobile__Dropdown ul {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 15px;
}
