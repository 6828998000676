.PauseOverlay__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-radius: 20px;
  border: solid var(--borderColor) 2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  gap: 1rem;
  cursor: auto;
}

.PauseOverlay__heading {
  color: var(--highlightColor);
  font-size: 32px;
  letter-spacing: -0.1rem;
  -webkit-text-stroke: 2px var(--borderColor);
}

.PauseOverlay__buttons button {
  background-color: var(--highlightColor);
  color: var(--backgroundColor);
  -webkit-text-stroke: 2px var(--borderColor);
  border: solid var(--borderColor) 2px;
  border-radius: 10px;
  font-size: 25px;
  width: 100px;
  height: 50px;
  box-shadow: 1px 2px 0px var(--borderColor);
  /* transition: 0.02s; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PauseOverlay__buttons svg {
  display: block;
  scale: 1.5;
  stroke: var(--borderColor);
  stroke-width: 1px;
}
