.Analyzer__container {
  width: 100%;
}

.Analyzer__container button {
  height: 40px;
  width: 100%;
}

.Analyzer_StepAnalyzer {
  display: flex;
}
