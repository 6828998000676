.ColorPalette__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 7px;
  max-height: 120px;
  width: 100%;
  height: 100%;
}
.ColorButton__container {
  cursor: pointer;
  /* border-radius: 5px; */
  border: solid black 1px;
  min-height: 30px;
  max-height: 40px;
}

@media (max-width: 900px) {
  .ColorPalette__container {
    gap: 3px;
  }
}
