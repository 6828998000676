.NumPad__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1rem; */
  width: 100%;
  /* max-width: 350px; */
}

.NumPad__grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-columns: repeat(10, 1fr); */
  grid-template-rows: repeat(2, 1fr);
  /* grid-template-rows: repeat(1, 1fr); */
  gap: 7px;
  /* gap: 1px; */
  width: 100%;
  /* min-width: 30px; */
}

.NumPad_Digit {
  width: 100%;
  /* max-width: 60px; */
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  cursor: pointer;
  font-size: 2.2rem;
  /* font-size: 21px; */
  background-color: var(--primaryColor);
  border: solid var(--borderColor) 2px;
  color: var(--backgroundColor);
  box-shadow: 1px 2px 0px var(--borderColor);
  -webkit-text-stroke: 2px var(--borderColor);
}

#DeleteButton {
  background-color: var(--highlightColor);
}

@media (max-width: 500px) {
  @media (min-aspect-ratio: 0.5) {
    .NumPad__grid {
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 3px;
    }

    .NumPad_Digit {
      font-size: 1.2em;
    }
  }
}
