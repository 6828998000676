.PresetPage__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;
  /* border: solid red 1px; */
}

.PresetPage__sudokuapp {
  /* position: relative; */
  max-width: var(--pageWidth);
  /* max-width: 1050px; */
  width: 100%;
  /* border: solid blue 1px; */
  /* padding: 0rem 1rem 0rem 1rem; */
}

.PresetPage__app-background {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--backgroundColor);
  margin: 5rem 0 2rem 0;
  padding: 2rem 1rem 2rem 1rem;
}

.PresetPage__finished-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColorTransparent);
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-stroke: 2px var(--borderColor);
  font-size: 3rem;
  font-weight: bold;
  z-index: 1;
  display: block;
}

.PresetPage__text-container {
  display: flex;
  justify-content: center;
  padding: 0rem 1rem 0rem 1rem;
}

.PresetPage__text-section {
  max-width: var(--pageWidth);
  width: 100%;
  /* padding: 0rem 1rem 0rem 1rem; */
  /* border: solid green 1px; */
  /* box-sizing: border-box; */
}

@media (max-width: 1024px) {
  .PresetPage__sudokuapp {
    /* padding-top: 1rem;
    padding-bottom: 1rem; */
    display: flex;
    justify-content: center;
    padding: 0rem 0.5rem 0rem 0.5rem;
  }

  .PresetPage__app-background {
    margin: 0rem 0 0rem 0;
    padding: 0rem 0 0rem 0;
  }
}
