.Database__container {
  display: flex;
  justify-content: space-between;
  /* border: solid red 1px; */
  height: 100%;
  width: 100%;
}

.Database__Toggle_Background {
  display: flex;
  /* gap: 5px; */
  background-color: var(--primaryColor);
  height: 100%;
  border-radius: 100vmax;
  border: solid var(--borderColor) 2px;
  padding: 0 3px 0 3px;
  box-shadow: 1px 2px 0px var(--borderColor);
}

.Database__Toggle_Background button {
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  height: calc(100% + 4px);
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0);
  border: solid rgba(0, 0, 0, 0), 2px;
  border-radius: 100vmax;
  /* min-width: 100px; */
  margin: -2px -5px -2px -5px;
  -webkit-tap-highlight-color: transparent;
  color: var(--backgroundColor);
  -webkit-text-stroke: 1px var(--borderColor);
}

.Database__timer {
  height: 100%;
}
