.NumPadBar__container {
  display: flex;
  flex-direction: row;
  /* align-items: stretch; */
  width: 100%;
  /* height: 50px; */
  border-radius: 100vmax;
  background-color: var(--primaryColor);
  border: solid var(--borderColor) 2px;
  box-shadow: 1px 2px 0px var(--borderColor);
  padding: 0px 5px 0px 5px;
  box-sizing: border-box;
}

.NumPadBar__container button {
  min-width: 80px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border-radius: 100vmax;
  border: solid rgba(0, 0, 0, 0), 2px;
  margin: -2px -7px -2px -7px;
  -webkit-tap-highlight-color: transparent;
  color: var(--backgroundColor);
  -webkit-text-stroke: 1px var(--borderColor);
}

.NumPadBar__button-content {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.NumPadBar_ButtonSubtext {
  font-size: 0.7rem;
  font-weight: 300;
  margin-top: -5px;
}

/* @media (max-width: 1024px) {
  .MainGrid__container {
    max-width: 600px;
    min-width: 200px;
  }
} */
