.App__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  --startPercent: 27%;
  --firstLineWidth: 10%;
  --secondLineWidth: 3%;
  --lineGapWidth: 1.5%;
}

.App__page-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
