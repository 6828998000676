.Sudoku_App__container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6vw;
  height: 100%;
  width: 100%;
  font-family: "Rubik Mono One";
  /* border: solid red 1px; */
  user-select: none;
}

.Sudoku_App__container button {
  font-family: "Rubik Mono One";
}

.Sudoku_App__overlay {
  position: absolute;
  /* border: solid blue 1px; */
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColorTransparent);
  z-index: 1;
}

.Sudoku_App__grid {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
  width: 100%;
  max-width: 50vh;
  /* max-width: 800px; */
  /* border: solid red 1px; */
}

.Sudoku_App__database {
  height: 2rem;
  /* border: solid blue 1px; */
}
.Sudoku_App__toolbar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}
.Sudoku_App__numpadbar {
  display: flex;
  height: 50px;
}

.Sudoku_App__controlboard {
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: solid var(--borderColor) 2px;
  box-shadow: 1px 2px 0px var(--borderColor);
}

/* Hochformat */
@media (orientation: portrait) {
  .Sudoku_App__container {
    flex-direction: column;
    gap: 1rem;
    min-height: calc(100vh - 50px);
    justify-content: center;
    align-items: center;
  }
}

/* Handy Modus */
@media (max-width: 500px) {
  .Sudoku_App__toolbar {
    max-width: 100%;
  }
  .Sudoku_App__grid {
    max-width: 100%;
  }
  @media (min-aspect-ratio: 0.47) {
    .Sudoku_App__container {
      gap: 0.5rem;
    }

    .Sudoku_App__toolbar {
      gap: 0.5rem;
    }
    .Sudoku_App__grid {
      gap: 0.5rem;
    }
    .Sudoku_App__database {
      height: 1.6rem;
    }
    .Sudoku_App__numpadbar {
      height: 40px;
    }
  }
}
